<template>
  <div :class="{ 'has-logo': showLogo }" :style="{ backgroundColor: sideTheme === 'theme-dark' ? variables.menuBackground : variables.menuLightBackground }">
    <logo v-if="showLogo" :collapse="isCollapse" />
    <el-scrollbar :class="sideTheme" wrap-class="scrollbar-wrapper">
      <el-menu
        ref="menu"
        :default-active="activeMenu"
        :collapse="isCollapse"
        :background-color="sideTheme === 'theme-dark' ? variables.menuBackground : variables.menuLightBackground"
        :text-color="sideTheme === 'theme-dark' ? variables.menuColor : variables.menuLightColor"
        :unique-opened="true"
        :active-text-color="theme"
        :collapse-transition="false"
        mode="vertical"
      >
        <sidebar-item
          v-for="(route, index) in sidebarRouters"
          :key="route.path + index"
          :item="route"
          :base-path="route.path"
        />
        
      </el-menu>
    </el-scrollbar>
  </div>
</template>

<script setup>
import { isExternal } from '@/utils/validate'
import Logo from './Logo'
import SidebarItem from './SidebarItem'
import variables from '@/assets/styles/variables.module.scss'
import useAppStore from '@/store/modules/app'
import useSettingsStore from '@/store/modules/settings'
import usePermissionStore from '@/store/modules/permission'
import { useRouter,useRoute } from 'vue-router'
import { getNormalPath } from '@/utils/ruoyi'

const route = useRoute();
const appStore = useAppStore()
const settingsStore = useSettingsStore()
const permissionStore = usePermissionStore()
const router = useRouter()
const menu=ref(null)

const sidebarRouters =  computed(() => permissionStore.sidebarRouters);
const showLogo = computed(() => settingsStore.sidebarLogo);
const sideTheme = computed(() => settingsStore.sideTheme);
const theme = computed(() => settingsStore.theme);
const isCollapse = computed(() => !appStore.sidebar.opened);
const isHide = computed(() => appStore.sidebar.hide);
const target=ref(getTarget())
const activeMenu = computed(() => {
  const { meta, path } = route;
  // if set path, the sidebar will highlight the path you set
  if (meta.activeMenu) {
    return meta.activeMenu;
  }
  return path;
})

const activePath=ref("")


function handleOpen(key, keyPath) {
        console.log(key, keyPath)
}
function goAdd(){
  router.push("/project")
}

function resolvePath(routePath) {
  if (isExternal(routePath)) {
    return routePath
  }
}

function getTarget(){
  var target=""
  if(router.currentRoute.value.query.target){
      target=router.currentRoute.value.query.target
  }else{
      target=router.currentRoute.value.fullPath.split("/")[1]
      target=target.replace(target.slice(0,1),target.slice(0,1).toUpperCase())
  }
  return target
}



function initTarget(){
  setTimeout(function(){
    if(document.querySelector(".openSidebar")!=null){
        target.value=router.currentRoute.value.query.target
        var key="/"+target.value.toLowerCase()+"/"+target.value.toLowerCase()
        setTimeout(function(){
            menu.value.open(key)
        },50)        
    }
  },50)
}

onMounted(()=>{
  if(router.currentRoute.value.fullPath=="/index"){
    let targetArr=sidebarRouters.value.filter(item=>{
      return item.hidden==false
    })
    if(targetArr.length>0){
      if(targetArr[0].name=="Xinyou"){
        router.push("/xinyou/xinyou_reward/xinyou_rewardmanage")

      }else if(targetArr[0].name=="Huaxin"){
        router.push("/huaxin/reward/rewardmanage")

      }else if(targetArr[0].name=="Finance"){
        router.push("/finance/financialManagement/finance_auditing")
      }
    }
  }
})

watch(
    ()=> router.currentRoute.value.query.target,     // 一个函数，返回监听属性
    (newVal, oldVal)=> {
        if(router.currentRoute.value.query.target){
          initTarget()
        }       
    },
    {
        immediate: true,     // 立即执行，默认为false
        deep: true     // 深度监听，默认为false
    }
)

defineExpose({
  initTarget
})

</script>

<style lang="scss" scoped>
.createBtn {
  display: block;
  margin: 15px auto!important;
  width: 80%;
}
.hideSidebar .createBtn{
  span{
    display: none;
  }
}

.openSidebar:deep(.el-sub-menu__title){
    display: block;
}
</style>